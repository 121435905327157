







































































import useMisc from "@/use/misc";
import useSelectItems from "@/use/selectItems";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEmailEditor: () =>
      import("@/components/molecules/emailEditor/m-email-editor.vue"),
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },

  setup(_, { root }) {
    const { getBase64, goBack } = useMisc({ root });
    const { registrationTypeItems } = useSelectItems({ root });
    const form = ref<any>(null);

    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      loaded: false,
      defaultTemplate: false,
      eventName: "",
      files: [] as any,
      valid: false,
    });

    const model = reactive<{
      editor: any;
      data: any;
    }>({
      data: {
        name: "",
        title: "",
        template: "",
        files: [],
        attachCertificate: false,
        attachTextToPdfMessage: false,
        textToPdfMessageContent: null,
      },
      editor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
    });

    //  Start fetch single message data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`message-template/other/${root.$route.params.mtid}`)
        .then(({ data }) => {
          model.data.name = data.name || undefined;
          model.editor.design = data.design || undefined;
          model.data.template = data.template || undefined;
          model.data.title = data.title || undefined;
          model.data.attachCertificate = data.attachCertificate || false;
          model.data.attachTextToPdfMessage = data.attachTextToPdfMessage;
          model.data.textToPdfMessageContent =
            data.textToPdfMessageContent || null;
          state.files = data.attachments || [];
          state.loaded = true;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single message data

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 405:
          return `${root.$tc("event.panel.modules.alertModule.add.error405")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const files: any[] = [];

      if (
        state.files &&
        state.files.length &&
        model.data.files &&
        model.data.files.length
      ) {
        for (const file of state.files) {
          const fileName = file.filename;
          const content = Buffer.from(file.content.data, "base64").toString(
            "ascii"
          );
          files.push({
            id: file.id,
            filename: fileName,
            content,
          });
        }
        for (const file of model.data.files) {
          const fileName = file.name;
          const content = await getBase64(file);
          files.push({
            filename: fileName,
            content,
          });
        }
      } else if (
        state.files.length === 0 &&
        model.data.files &&
        model.data.files.length
      ) {
        for (const file of model.data.files) {
          const fileName = file.name;
          const content = await getBase64(file);
          files.push({
            filename: fileName,
            content,
          });
        }
      } else if (state.files && state.files.length) {
        for (const file of state.files) {
          const fileName = file.filename;
          const content = Buffer.from(file.content.data, "base64").toString(
            "ascii"
          );
          files.push({
            id: file.id,
            filename: fileName,
            content,
          });
        }
      }

      const data = {
        name: model.data.title,
        title: model.data.title || null,
        design: model.editor.design || undefined,
        template: model.editor.html || undefined,
        attachments: files,
        attachCertificate: model.data.attachCertificate || false,
        attachTextToPdfMessage: model.data.attachTextToPdfMessage || false,
        textToPdfMessageContent: model.data.textToPdfMessageContent || null,
      };

      state.loading = true;

      axiosInstance
        .put(`message-template/other/${root.$route.params.mtid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          model.editor = {};
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("panel.event.abstract.config.mails.save"),
          });
          setTimeout(() => {
            goBack("panel.event.view.alertModule.list");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.eventName = event.name;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchEvent);

    watch(
      () => model.data.title,
      () => {
        if (model.data.title?.includes("{{ event.name }}")) {
          model.data.title = model.data.title.replace(
            "{{ event.name }}",
            state.eventName
          );
        }
      }
    );

    const removeFile = (id: string) => {
      const filtered = state.files.filter((i: any) => i.id !== id);
      state.files = filtered;
    };

    const rules = {
      required: [(v: string) => !!v || root.$t("layout.misc.required")],
      files: [
        (v: any) =>
          ((v.length <= 3 || state.files.length <= 3) &&
            v.length + state.files.length <= 3) ||
          root.$tc("panel.event.add.alert.toManyFiles"),
      ],
    };

    return {
      form,
      state,
      model,
      onSubmit,
      getErrorMessage,
      fetchData,
      registrationTypeItems,
      removeFile,
      rules,
    };
  },
});
